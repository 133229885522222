import { useState, useEffect, useRef } from "react";
import { fetchProviders, fetchRace } from "../api/market";
import { fetchUSNProviders, fetchUSNRaceData } from "../api/usnMarket";
import Loader from "./Common/Loader";
import ReactECharts from "echarts-for-react";
import { RaceChartSection, RaceChartWrapper } from "./Styles/Chart.styled";
import { Button } from "reactstrap";
import { errornotify } from "./Message";
import { useSelector } from "react-redux";

interface Providers {
  provider_id: string;
  provider_name: string;
}

interface PlanRateItem {
  rank: number;
  plan_id: string;
  base_rate: string;
  date: string;
}

type DatasetItem = Array<number | string>;

const colors = [
  "#37A2DA",
  "#32C5E9",
  "#67E0E3",
  "#9FE6B8",
  "#FFDB5C",
  "#ff9f7f",
  "#fb7293",
  "#E062AE",
  "#E690D1",
  "#e7bcf3",
  "#9d96f5",
  "#8378EA",
  "#96BFFF",
];

const options = {
  grid: {
    top: 20,
    bottom: 32,
    left: 180,
    right: 44,
  },
  xAxis: {
    max: "dataMax",
    min: function (value: any) {
      return value.min - 0.5;
    },
    axisLabel: {
      // formatter: (n: number) => '' + Math.round(n),
      precision: 0.25,
    },
  },
  dataset: {
    source: [],
  },
  yAxis: {
    type: "category",
    max: 9,
    axisLabel: {
      show: true,
      fontSize: 14,
    },
    animationDuration: 600,
    animationDurationUpdate: 600,
    inverse: false,
  },
  series: [
    {
      realtimeSort: true,
      seriesLayoutBy: "column",
      type: "bar",
      itemStyle: {
        color: (param: any) => {
          return colors[param.data[0].length % 10] || "#f49f42";
        },
      },
      encode: {
        x: 1,
        y: 0,
      },
      label: {
        show: true,
        precision: 0.25,
        position: "right",
        valueAnimation: true,
        formatter: (val: any) => val.data[1] + "¢",
      },
    },
  ],
  animationDuration: 0,
  animationDurationUpdate: 2000,
  animationEasing: "linear",
  animationEasingUpdate: "linear",
};

export const RaceChart = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const isTX = currentLocation?.state_id === "TX";
  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState<Providers[]>([]);
  const [rateData, setRateData] = useState<{ [key: number]: PlanRateItem[] }>(
    {}
  );
  const [formattedData, setFormattedData] = useState<{
    [key: string]: DatasetItem[];
  }>({});
  const [chartData, setChartData] = useState<any>(options);
  const [allDate, setAllDate] = useState<string[]>([]);
  const [isNoData, setIsNoData] = useState(false);
  const currentDateIdx = useRef(0);
  const timer = useRef<ReturnType<typeof setTimeout> | "">("");
  const [price, setPrice] = useState("500");

  useEffect(() => {
    clearInterval(timer.current);
    currentDateIdx.current = 0;
    setChartData(options);

    const getProviders = async () => {
      setRateData({});
      const res = isTX ? await fetchProviders() : await fetchUSNProviders();
      setProviders(res?.status === 1 ? res.response : []);
    };

    getProviders();
  }, [currentLocation]);

  useEffect(() => {
    const getRaceData = async () => {
      const ids = providers.map((item) => item.provider_id);

      setIsNoData(false);
      setLoading(true);

      const res = isTX
        ? await fetchRace(1, ids, +price)
        : await fetchUSNRaceData(currentLocation?.state_id);

      if (res && res.status === 1) {
        setRateData(res.response);
        setLoading(false);
      } else {
        // errornotify(res.message || "Error");
        setIsNoData(true);
        setLoading(false);
      }
    };

    if (providers.length > 0) getRaceData();
  }, [price, providers]);

  useEffect(() => {
    if (JSON.stringify(rateData) !== "{}") {
      const allDate: string[] = [];
      for (let rep in rateData) {
        const plans = rateData[rep];
        plans.forEach((i) => {
          if (allDate.indexOf(i.date) < 0) allDate.push(i.date);
        });
      }
      let _data: { [key: string]: DatasetItem[] } = {};
      allDate.forEach((date) => {
        for (let rep in rateData) {
          const plans = rateData[rep];
          const record: PlanRateItem[] = plans.filter(
            (plan) => plan.date === date
          );
          const provider = providers.filter(
            (provider) => provider.provider_id === rep
          );
          if (!_data[date]) _data[date] = [];
          _data[date].push([
            provider && provider[0] ? provider[0].provider_name : "",
            record && record[0] ? parseFloat(record[0].base_rate) : 0,
          ]);
        }
      });
      setAllDate(allDate);
      setFormattedData(_data);
    }
  }, [rateData]);

  useEffect(() => {
    if (JSON.stringify(formattedData) === "{}") return;

    timer.current = setInterval(() => {
      if (currentDateIdx.current >= allDate.length) {
        clearInterval(timer.current);
        return;
      }
      const currentDate = allDate[currentDateIdx.current];
      const dailyData = formattedData[currentDate];
      const sortedData = dailyData.sort(
        (a, b) => (a[1] as number) - (b[1] as number)
      );
      const validData = sortedData.filter((item) => item[1] !== 0).slice(0, 10);
      let newOptions = Object.assign({}, chartData, {
        dataset: {
          source: validData,
        },
      });
      newOptions.yAxis.max = validData.length - 1;

      setChartData(newOptions);
      currentDateIdx.current += 1;
    }, 2000);

    return () => {
      clearInterval(timer.current);
    };
  }, [formattedData]);

  const changeUsage = (val: string) => {
    clearInterval(timer.current);
    setPrice(val);
    currentDateIdx.current = 0;
  };

  return (
    <RaceChartSection>
      <h2>
        {`Lowest 10 providers by daily bundled rate in ${currentLocation?.state_name}`}{" "}
        {isTX && (
          <span>
            {allDate[currentDateIdx.current]
              ? `(${allDate[currentDateIdx.current]})`
              : ""}
          </span>
        )}
      </h2>
      {isTX && (
        <div className="d-flex flex-wrap gap-2 mt-3 mb-3">
          {["500", "1000", "2000"].map((val: string, idx: number) => (
            <Button
              onClick={() => changeUsage(val)}
              color={price === val ? "secondary" : "light"}
              key={idx}
            >
              {val} kWh
            </Button>
          ))}
        </div>
      )}
      <RaceChartWrapper>
        {loading ? (
          <Loader />
        ) : isNoData ? (
          <p>No Data</p>
        ) : (
          <ReactECharts option={chartData} />
        )}
      </RaceChartWrapper>
    </RaceChartSection>
  );
};
