import React, { useContext, useEffect, useState } from "react";
import "../styles/myplan.css";
import moment from "moment";
import { Card, CardBody, CardHeader } from "reactstrap";
import DataTable from "react-data-table-component";
import Loader from "./Common/Loader";
import { Utility } from "../App";
// import { errornotify } from "./Message";
import { useSelector } from "react-redux";
import { NoResult } from "./Common/NoResult";
import { fetchUSNMarketAverageRate } from "../api/usnMarket";
// import Datepicker from "./Datepicker";

interface MarketPriceProps {
  average_price: number;
  trend_rate: string;
  utility_name: string;
}

const USN_AverageRateMarketView = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const { utilityList } = useContext(Utility);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState({
    utility_id: "",
    created_ts: moment().format("YYYY-MM-DD"),
  });
  const [dataPlanList, setDataPlanList] = useState<Array<MarketPriceProps>>([]);
  const [findData, setFindData] = useState(true);

  const getPlanList = async (val: any) => {
    setIsLoading(true);
    setDataPlanList([]);
    setFindData(false);
    const res = await fetchUSNMarketAverageRate(currentLocation?.state_id, "1");

    if (res.status !== 1 || res.response.length === 0) {
      setFindData(false);
      // errornotify(res.message || "Error");
      return setIsLoading(false);
    }
    setDataPlanList(res.response);
    setFindData(true);
    setIsLoading(false);
  };
  useEffect(() => {
    getPlanList(filterValue);
  }, [currentLocation, filterValue, utilityList]);

  const changeDate = (val: any) => {
    setFilterValue({
      ...filterValue,
      created_ts: moment(val).format("YYYY-MM-DD"),
    });
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2F2F2",
        paddingRight: 0,
      },
    },
  };

  const formatTrend = (percentage: string) => {
    const value = parseFloat(percentage);
    if (value > 0) {
      return (
        <span className="d-flex align-items-center gap-1 text-danger">
          <i className="ri-arrow-right-up-line" />
          {percentage}
        </span>
      );
    } else if (value < 0) {
      return (
        <span className="d-flex align-items-center gap-1 text-success">
          <i className="ri-arrow-right-down-line" />
          {percentage}
        </span>
      );
    } else {
      return percentage;
    }
  };

  const TopPlan = () => {
    const columns = [
      {
        name: <span className="font-weight-bold fs-14">Name</span>,
        selector: (row: any) => row.utility_name,
      },
      {
        name: <span className="font-weight-bold fs-14">Average Rate</span>,
        selector: (row: any) => `${row.average_price}¢`,
      },
      {
        name: <span className="font-weight-bold fs-14">Trend Change</span>,
        selector: (row: any) => formatTrend(row.trend_rate),
      },
    ];
    return (
      <DataTable
        columns={columns}
        data={dataPlanList}
        paginationPerPage={10}
        customStyles={customStyles}
        pagination
      />
    );
  };

  return (
    <div className="layout-width">
      <Card>
        <CardHeader className="align-items-center justify-content-between d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Market View – Average Rate
          </h4>
        </CardHeader>
        <CardBody>
          {/* <div className="d-flex justify-content-end mb-4">
            <Datepicker handleChangeDate={(val: any) => changeDate(val)} />
          </div> */}
          {isLoading ? (
            <Loader />
          ) : (
            <div className="rdt_out">
              {findData ? <TopPlan /> : <NoResult />}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
export default USN_AverageRateMarketView;
