import { useEffect, useState } from "react";
import { prompts } from "../common/prompts";
import { fetchAiSummaryResult } from "../api/ai";
import { Spinner } from "reactstrap";
import showdown from "showdown";
import { Auth } from "aws-amplify";
import "../styles/ai-summary.css";
// import { TypeAnimation } from "react-type-animation";

const AiSummary = ({
  data,
  section = "common",
}: {
  data: string;
  section?: string;
}) => {
  const prompt = prompts[section] || "";
  const [summaryResult, setSummaryResult] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  // const [hideBtn, setHideBtn] = useState(false);
  const [open, setOpen] = useState<number>(0);
  const [isValidUser, setIsValidUser] = useState(false);

  const converter = new showdown.Converter();

  useEffect(() => {
    const validateUser = async () => {
      const user = await Auth.currentUserInfo();
      if (user) {
        const email = user?.attributes?.email || "";
        if (
          email === "peter.liu@boringpower.com" ||
          email === "xiongfeng.zhou@boringpower.com"
        ) {
          setIsValidUser(true);
        }
      }
      // console.log(user);
    };
    validateUser();
  }, []);

  useEffect(() => {
    if (isValidUser && data) {
      getResult();
    }
  }, [isValidUser, data]);

  const converateToJson = (markdownString: string) => {
    const jsonMatch = markdownString.match(/```json([\s\S]*?)```/);
    if (jsonMatch && jsonMatch[1]) {
      const jsonString = jsonMatch[1].trim();

      try {
        const jsonObject = JSON.parse(jsonString);
        return jsonObject;
      } catch (error) {
        console.error("JSON parse error:", error);
        return null;
      }
    } else {
      console.log("Not a JSON string:", markdownString);
      return null;
    }
  };

  const getResult = () => {
    // console.log(data);
    // console.log(prompt);
    setLoading(true);
    // setHideBtn(true);
    setOpen(0);
    fetchAiSummaryResult(data, prompt).then((res) => {
      setLoading(false);
      const jsonRes = converateToJson(res);

      // console.log(jsonRes);

      if (jsonRes) {
        const qa = jsonRes?.qa || [];
        if (jsonRes?.summarize) {
          qa.unshift({
            question: "Overview",
            answer: jsonRes.summarize,
          });
        }
        setSummaryResult(qa);
      } else {
        setSummaryResult([]);
        // setHideBtn(false);
      }
    });
  };

  const renderSummaryContent = () => {
    const qa = summaryResult[open];
    if (qa) {
      const convertedValue = converter.makeHtml(qa.answer);
      let formatedValue = convertedValue.replace(/ ✦/g, "<br /><br />✦");

      return (
        <div className="ai-summary-content">
          <div dangerouslySetInnerHTML={{ __html: formatedValue }} />
        </div>
      );
    }
    return null;
  };

  // console.log(summaryResult);

  if (!isValidUser || !data || !prompt) return null;

  return (
    <section className="ai-summary-section">
      <h4>✨ Ai Analysis</h4>
      <div className="ai-summary-container">
        {/* {!hideBtn && (
        <button type="button" className="ai-summary-btn" onClick={getResult}>
          ✨ AI Summary <i>(Beta)</i>
        </button>
      )} */}

        {loading && (
          <div className="ai-summary-thinking">
            <Spinner
              size="sm"
              type="grow"
              color="secondary"
              style={{ flexShrink: 0 }}
            >
              Loading...
            </Spinner>
            <span>AI is analyzing data, summary will be ready shortly…</span>
          </div>
        )}

        {!loading && summaryResult?.length > 0 && (
          <>
            <div className="ai-summary-tab-group">
              {summaryResult?.map((item: any, index: number) => (
                <button
                  className={open === index ? "active" : ""}
                  key={index}
                  onClick={() => setOpen(index)}
                >
                  {item.question}
                </button>
              ))}
            </div>
            {renderSummaryContent()}
          </>
        )}
      </div>
    </section>
  );
};

export default AiSummary;
