import { useEffect, useState } from "react";
import CountUp from "react-countup";
import Loader from "./Common/Loader";
import { fetchProviderList } from "../api/market";
import { errornotify } from "./Message";
import { getLogoSrc } from "../helpers/func";
import { useSelector } from "react-redux";
import { fetchUSNProviderList } from "../api/usnMarket";

interface ProviderBasicInfoProps {
  plan_nums: number;
  provider_id: string;
  provider_name: string;
  time: string;
}

export const MySummary = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const isTX = currentLocation?.state_id === "TX";
  const [provider, setProvider] = useState<ProviderBasicInfoProps | undefined>(
    undefined
  );

  useEffect(() => {
    getProvider();
  }, [currentLocation]);

  const getProvider = async () => {
    const res = isTX
      ? await fetchProviderList()
      : await fetchUSNProviderList(currentLocation?.state_id);
    if (res.status !== 1) {
      return errornotify(res.message || "Error");
    }
    setProvider(res.response);
  };
  
  return (
    <div className="layout-width">
      <div className="col-xl-12">
        <div className="card crm-widget">
          <div className="card-body p-0">
            <div className="row row-cols-md-3 row-cols-1 g-0">
              <div className="col">
                <div className="pt-4 pb-3 px-4">
                  <h5 className="text-muted text-uppercase fs-16 mb-0">
                    Provider
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      {provider ? (
                        <span className="counter-value">
                          <img
                            src={getLogoSrc(
                              provider.provider_id,
                              currentLocation?.state_id
                            )}
                            alt=""
                            height="80"
                          />
                        </span>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="pt-4 pb-3 px-4">
                  <h5 className="text-muted text-uppercase fs-16 mb-3">
                    Total Plans
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      {provider ? (
                        <h2 className="mb-0">
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              separator=","
                              end={provider.plan_nums}
                              duration={1}
                            />
                          </span>
                        </h2>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="pt-4 pb-3 px-4">
                  <h5 className="text-muted text-uppercase fs-16 mb-3">
                    Update Time
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      {provider ? (
                        <h2 className="mb-0">
                          <span className="counter-value">{provider.time}</span>
                        </h2>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
