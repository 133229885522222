import { BarDatum, ResponsiveBar } from "@nivo/bar";
import {
  ChartBlock,
  ChartFooter,
  ChartTitle,
  ChartWrapper,
  CustomTooltip,
  CustomTooltipYItem,
  CustomTooltipYLabel,
  CustomTooltipYVal,
} from "./Styles/Chart.styled";
import Loader from "./Common/Loader";
import { NoResult } from "./Common/NoResult";

interface MyBarChartProps {
  type: "PeakTimeBarChart" | "AllPTCRateBarChart";
  title?: string;
  data: BarDatum[];
  size?: string;
  loading?: boolean;
  footer?: string;
}

const colors = [
  "#37A2DA",
  "#32C5E9",
  "#67E0E3",
  "#9FE6B8",
  "#FFDB5C",
  "#ff9f7f",
  "#fb7293",
  "#E062AE",
  "#E690D1",
  "#e7bcf3",
  "#9d96f5",
  "#8378EA",
  "#96BFFF",
];

export const MyBarChart: React.FC<MyBarChartProps> = ({
  type,
  title,
  data,
  size,
  loading,
  footer,
}) => {
  const getTspanGroups = (value: string) => {
    const words = value.split(" ");
    const maxLines = 2;
    const maxLineLength = 12;

    type linesAcc = {
      lines: string[];
      currLine: string;
    };

    //reduces the words into lines of maxLineLength
    const assembleLines: linesAcc = words.reduce(
      (acc: linesAcc, word: string) => {
        //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
        if (
          (word + acc.currLine).length > maxLineLength &&
          acc.currLine !== ""
        ) {
          return {
            lines: acc.lines.concat([acc.currLine]),
            currLine: word,
          };
        }
        //otherwise add the word to the current line
        return {
          ...acc,
          currLine: acc.currLine + " " + word,
        };
      },
      { lines: [], currLine: "" }
    );

    //add the ending state of current line (the last line) to lines
    const allLines = assembleLines.lines.concat([assembleLines.currLine]);

    //for now, only take first 2 lines due to tick spacing and possible overflow
    const lines = allLines.slice(0, maxLines);
    let children: JSX.Element[] = [];
    let dy = 0;

    lines.forEach((lineText, i) => {
      children.push(
        <tspan x={0} dy={dy} key={i}>
          {
            // if on the second line, and that line's length is within 3 of the max length, add ellipsis
            1 === i && allLines.length > 2
              ? lineText.slice(0, maxLineLength - 3) + "..."
              : lineText
          }
        </tspan>
      );
      //increment dy to render next line text below
      dy += 15;
    });

    return children;
  };

  const chartContent = () => {
    if (loading) return <Loader />;

    if (data.length > 0) {
      let chartProps = {};

      if (type === "PeakTimeBarChart") {
        chartProps = {
          keys: ["on_peak", "off_peak", "mid_peak", "super_off_peak"],
          indexBy: "date",
          margin: { top: 50, right: 60, bottom: 40, left: 60 },
          padding: 0.3,
          groupMode: "grouped",
          valueScale: { type: "linear" },
          indexScale: { type: "band", round: true },
          colors: (bar: any) => {
            switch (bar.id) {
              case "on_peak":
                return "#37A2DA";
              case "off_peak":
                return "#67E0E3";
              case "mid_peak":
                return "#FFDB5C";
              case "super_off_peak":
                return "#fb7293";
            }
          },
          borderColor: {
            from: "color",
            modifiers: [["darker", 1.6]],
          },
          axisTop: null,
          axisRight: null,
          axisBottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Month",
            legendPosition: "middle",
            legendOffset: 32,
            // truncateTickAt: 0,
          },
          axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Trends",
            legendPosition: "middle",
            legendOffset: -40,
            // truncateTickAt: 0,
          },
          enableLabel: false,
          labelSkipWidth: 12,
          labelSkipHeight: 12,
          labelTextColor: {
            from: "color",
            modifiers: [["darker", 1.6]],
          },
          tooltip: (bar: any) => {
            const label = bar?.id
              ?.split("_")
              .map((word: string) => word[0].toUpperCase() + word.slice(1))
              .join(" ") || "";
            return (
              <CustomTooltip>
                <CustomTooltipYItem>
                  <CustomTooltipYLabel color={bar.color}>
                    <i />
                    <span>{label}</span>
                  </CustomTooltipYLabel>
                  <CustomTooltipYVal>{`${bar.value}¢`}</CustomTooltipYVal>
                </CustomTooltipYItem>
              </CustomTooltip>
            );
          },
          role: "application",
          ariaLabel: "Rate Trends by Peak Time",
          barAriaLabel: (e: any) =>
            e.id + ": " + e.formattedValue + " - " + e.indexValue,
        };
      }

      if (type === "AllPTCRateBarChart") {
        chartProps = {
          keys: ["ptc"],
          indexBy: "utility_name",
          margin: { top: 0, right: 40, bottom: 60, left: 60 },
          padding: 0.7,
          valueScale: { type: "linear" },
          indexScale: { type: "band", round: true },
          colors: (bar: any) => colors[bar.index] || colors[0],
          borderColor: {
            from: "color",
            modifiers: [["darker", 1.6]],
          },
          axisTop: null,
          axisRight: null,
          axisBottom: {
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Utility",
            legendPosition: "middle",
            legendOffset: 50,
            // truncateTickAt: 0,
            renderTick: ({
              opacity,
              textAnchor,
              textBaseline,
              textX,
              textY,
              value,
              x,
              y,
            }: {
              opacity: any;
              textAnchor: any;
              textBaseline: any;
              textX: any;
              textY: any;
              value: any;
              x: any;
              y: any;
            }) => {
              return (
                <g transform={`translate(${x},${y})`} style={{ opacity }}>
                  <text
                    alignmentBaseline={textBaseline}
                    style={{
                      fill: "rgb(51, 51, 51)",
                      fontFamily: "sans-serif",
                      fontSize: "11px",
                    }}
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY})`}
                  >
                    {getTspanGroups(value)}
                  </text>
                </g>
              );
            },
          },
          axisLeft: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Rate",
            legendPosition: "middle",
            legendOffset: -40,
            // truncateTickAt: 0,
          },
          enableLabel: false,
          labelSkipWidth: 12,
          labelSkipHeight: 12,
          labelTextColor: {
            from: "color",
            modifiers: [["darker", 1.6]],
          },
          tooltip: (bar: any) => {
            return (
              <CustomTooltip>
                <CustomTooltipYItem>
                  <CustomTooltipYLabel color={bar.color}>
                    <i />
                    <span>{bar.indexValue}</span>
                  </CustomTooltipYLabel>
                  <CustomTooltipYVal>{`${bar.value}¢`}</CustomTooltipYVal>
                </CustomTooltipYItem>
              </CustomTooltip>
            );
          },
          role: "application",
          ariaLabel: "All PTC Rate",
          barAriaLabel: (e: any) =>
            e.id + ": " + e.formattedValue + " - " + e.indexValue,
        };
      }

      return <ResponsiveBar data={data} {...chartProps} />;
    }
    return <NoResult />;
  };

  return (
    <ChartBlock>
      {title && <ChartTitle>{title}</ChartTitle>}
      <ChartWrapper xs={size && size === "xs" ? true : false}>
        {chartContent()}
      </ChartWrapper>
      {footer && <ChartFooter>{footer}</ChartFooter>}
    </ChartBlock>
  );
};
