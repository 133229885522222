import React, { useEffect, useState } from "react";
import Loader from "./Common/Loader";
import "../styles/myplan.css";
import moment from "moment";
import { Button, Card, CardBody, CardHeader, Spinner } from "reactstrap";
import DataTable from "react-data-table-component";
import { NoResult } from "./Common/NoResult";
import { useSelector } from "react-redux";
import {
  exportUSNPTCRateHistory,
  fetchUSNPTCRateTrend,
} from "../api/stateInsight";
import Datepicker from "./Datepicker";

interface PTCPriceResult {
  date: string;
  mid_peak: number;
  off_peak: number;
  on_peak: number;
  ptc: number;
  service_type: string;
  service_type_name: string;
  state_id: string;
  state_utility_id: string;
  super_off_peak: number;
  unit: string;
  utility_code: string;
  utility_id: string;
  utility_name: string;
}

const USN_PTCPriceUtilityHistory = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const [isLoading, setIsLoading] = useState(false);
  const [filterValue, setFilterValue] = useState({
    utility_id: "",
  });
  const [dataPlanList, setDataPlanList] = useState<PTCPriceResult[]>([]);
  const [termValue, setTermValue] = useState("All");
  const [findData, setFindData] = useState(true);
  const [isDownload, setIsDownload] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "years").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const getPlanList = async (val: any) => {
    setIsLoading(true);
    setDataPlanList([]);
    setFindData(false);
    const res = await fetchUSNPTCRateTrend(
      currentLocation?.state_id,
      "1",
      `${startDate}&${endDate}`
    );
    if (res.status !== 1 || res.response.length === 0) {
      setFindData(false);
      return setIsLoading(false);
    }
    setFindData(true);
    setDataPlanList(res.response);
    setIsLoading(false);
  };

  useEffect(() => {
    getPlanList(filterValue);
  }, [currentLocation, filterValue, startDate, endDate]);

  const TopPlan = () => {
    const columns = [
      // {
      //   name: <span className="font-weight-bold fs-14">ID</span>,
      //   selector: (row: PTCPriceResult) => row.state_utility_id,
      // },
      {
        name: <span className="font-weight-bold fs-14">State</span>,
        selector: (row: PTCPriceResult) => row.state_id,
      },
      // {
      //   name: <span className="font-weight-bold fs-14">Service Type</span>,
      //   selector: (row: PTCPriceResult) => row.service_type_name,
      // },
      {
        name: <span className="font-weight-bold fs-14">Date</span>,
        selector: (row: PTCPriceResult) => row.date,
      },
      {
        name: <span className="font-weight-bold fs-14">Utility Code</span>,
        selector: (row: PTCPriceResult) => row.utility_code,
      },
      {
        name: <span className="font-weight-bold fs-14">Utility Name</span>,
        selector: (row: PTCPriceResult) => row.utility_name,
      },
      {
        name: <span className="font-weight-bold fs-14">PTC Rate</span>,
        selector: (row: PTCPriceResult) => `${row.ptc}¢`,
      },
      {
        name: <span className="font-weight-bold fs-14">On Peak</span>,
        selector: (row: PTCPriceResult) => row.on_peak,
        sortable: true,
      },
      {
        name: <span className="font-weight-bold fs-14">Min Peak</span>,
        selector: (row: PTCPriceResult) => row.mid_peak,
        sortable: true,
      },
      {
        name: <span className="font-weight-bold fs-14">Off Peak</span>,
        selector: (row: PTCPriceResult) => row.off_peak,
        sortable: true,
      },
      {
        name: <span className="font-weight-bold fs-14">Super Off Peak</span>,
        selector: (row: PTCPriceResult) => row.super_off_peak,
        sortable: true,
      },
    ];
    const customStyles = {
      headCells: {
        style: {
          backgroundColor: "#F2F2F2",
          paddingRight: 0,
        },
      },
    };

    return (
      <DataTable
        columns={columns}
        data={dataPlanList}
        paginationPerPage={20}
        customStyles={customStyles}
        pagination
      />
    );
  };

  const filterSelections = () => {
    let list = [],
      key = 0;
    if (termValue !== "All") {
      list.push(
        <button
          key={key++}
          className="d-flex align-items-center btn-soft-secondary btn-sm me-2"
          onClick={() => setTermValue("All")}
        >
          {`Term: ${termValue}`}
          <i className="bx bx-x fs-15 ms-2" />
        </button>
      );
    }
    return list.length > 0 ? (
      <div className="d-flex align-items-center">
        Active Filters:
        <div className="d-flex ms-3">{list}</div>
      </div>
    ) : null;
  };

  const exportList = async () => {
    setIsDownload(true);
    const res = await exportUSNPTCRateHistory(
      currentLocation?.state_id,
      "1",
      `${startDate}&${endDate}`
    );

    const fileName = res.headers
      .get("content-disposition")
      ?.split("filename=")[1];

    const data = await res.blob();

    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute(
      "download",
      fileName || `UtilityHistory_${startDate}-${endDate}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownload(false);
  };

  return (
    <div className="layout-width">
      <Card style={{ minHeight: "30rem" }}>
        <CardHeader className="align-items-center justify-content-between d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Utility history - Electricity PTC Price in{" "}
            {currentLocation?.state_name}
          </h4>
          <div className="flex-shrink-0">
            {!isDownload ? (
              <Button
                color="secondary"
                onClick={exportList}
                className="btn-label"
              >
                <i className="bx bx-download label-icon align-middle fs-17 me-2"></i>
                <span>Download</span>
              </Button>
            ) : (
              <Button color="secondary" className="btn-load" outline>
                <span className="d-flex align-items-center">
                  <Spinner size="sm" className="flex-shrink-0">
                    {" "}
                    Loading...{" "}
                  </Spinner>
                  <span className="flex-grow-1 ms-2">Loading...</span>
                </span>
              </Button>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <div className="d-flex justify-content-end flex-wrap gap-2 mb-3">
            <Datepicker
              defaultDate={startDate}
              minDate={moment("2023-01-01").format("YYYY-MM-DD")}
              handleChangeDate={(val: any) => setStartDate(val)}
              disabled={isLoading}
            />
            <span className="hide-xs">{" - "}</span>
            <Datepicker
              handleChangeDate={(val: any) => setEndDate(val)}
              disabled={isLoading}
            />
          </div>
          <div className="mb-4 d-flex justify-content-between">
            <div>{filterSelections()}</div>
          </div>

          {isLoading ? (
            <Loader />
          ) : (
            <div className="rdt_out">
              {findData ? <TopPlan /> : <NoResult />}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
export default USN_PTCPriceUtilityHistory;
