import { createContext, useEffect, useState } from "react";
import "./assets/scss/themes.scss";
import "react-tooltip/dist/react-tooltip.css";
import Route from "./Routes/index";
import { fetchUtilityId } from "./api/market";
import { fetchEflDoc } from "./api/myplan";
import { errornotify } from "./Components/Message";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { eflUrl, eflUrlDisplay, fetchStateRequest } from "./store/actions";
import { UtilityDataTypes } from "./types";
import { fetchUSNUtilityId } from "./api/usnMarket";
import { selectIsLoading, selectLocation } from "./store/location/selector";
import { Hub } from "aws-amplify";
import Loader from "./Components/Common/Loader";
// import { UrlModal } from "./Components/UrlModal";

export const Utility = createContext<any>({});

export default function App() {
  const dispatch = useDispatch();
  const currentLocation = useSelector(selectLocation);
  const isLoadingLocation = useSelector(selectIsLoading);
  const [utilityList, setUtilityList] = useState<UtilityDataTypes[]>([]);
  const [isLoadingUtility, setIsLoadingUtility] = useState(true);

  useEffect(() => {
    dispatch(fetchStateRequest());
  }, [dispatch]);

  useEffect(() => {
    const listener = ({ payload }: { payload: any }) => {
      const { event } = payload;
      switch (event) {
        case "signIn":
        case "signOut":
          console.log("signin-signout");
          dispatch(fetchStateRequest());
          break;
      }
    };

    Hub.listen("auth", listener);

    return () => {
      Hub.remove("auth", listener);
    };
  }, []);

  const getUtilityId = async (stateId: string) => {
    const isTX = stateId === "TX";
    const res = isTX
      ? await fetchUtilityId()
      : await fetchUSNUtilityId(stateId);

    setIsLoadingUtility(false);
    if (res.status === 1) {
      if (isTX) {
        setUtilityList(res.response);
      } else {
        setUtilityList(res.response.electricity);
      }
    } else {
      errornotify(res.message);
    }
  };

  useEffect(() => {
    if (isLoadingLocation || !currentLocation) return;
    getUtilityId(currentLocation?.state_id || "TX");
  }, [currentLocation, isLoadingLocation]);

  const getEflDoc = async (plan_id: any, pd_id: any) => {
    const res = await fetchEflDoc(plan_id, pd_id);
    if (res.status === 1) {
      dispatch(eflUrl(res.response));
      dispatch(eflUrlDisplay(true));
    } else {
      return errornotify(res.message);
    }
  };

  return (
    <div className="App">
      <Utility.Provider value={{ utilityList, getEflDoc }}>
        {!isLoadingLocation && !isLoadingUtility ? (
          <Route />
        ) : (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </Utility.Provider>
      <ToastContainer />
      {/* <UrlModal/> */}
    </div>
  );
}
