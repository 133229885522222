import React, { useEffect, useState } from "react";
import "../styles/myplan.css";
import moment from "moment";
import { Card, CardBody, CardHeader } from "reactstrap";
import Loader from "./Common/Loader";
import { NoResult } from "./Common/NoResult";
import { fetchUSNAverageRate } from "../api/usnMarket";
import { useSelector } from "react-redux";
import TduSelector from "./TduSelector";

const USN_AverageRateGlance = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);

  const [utilityId, setUtilityId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [findData, setFindData] = useState(true);
  const [data, setData] = useState<any>();
  const [selectedTDU, setselectedTDU] = useState<any>();

  useEffect(() => {
    if (!currentLocation) return;
    setUtilityId("");
    setselectedTDU("All Utility");
  }, [currentLocation]);

  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      fetchUSNAverageRate(currentLocation?.state_id, utilityId).then((res) => {
        if (res.status === 1) {
          setData(res.response);
        } else {
          setFindData(false);
        }
        setIsLoading(false);
      });
    };

    getData();
  }, [utilityId]);

  const AverageRate = () => {
    let {
      yesterday_average_rate,
      last_week_average_rate,
      last_month_average_rate,
      last_year_average_rate,
    } = data || {};
    return (
      <div className="list-averageRate">
        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">{`${moment()
              .subtract(1, "days")
              .format("MM/DD/YYYY")}`}</h4>
          </CardHeader>
          <CardBody>
            <h5>{yesterday_average_rate || 0}¢</h5>
          </CardBody>
        </Card>

        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">7 Days Ago</h4>
          </CardHeader>
          <CardBody>
            <h5>{last_week_average_rate || 0}¢</h5>
          </CardBody>
        </Card>

        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">30 Days Ago</h4>
          </CardHeader>
          <CardBody>
            <h5>{last_month_average_rate || 0}¢</h5>
          </CardBody>
        </Card>

        <Card className="card-averageRate">
          <CardHeader className="align-items-center justify-content-between d-flex">
            <h4 className="card-title mb-0 flex-grow-1">1 Year Ago</h4>
          </CardHeader>
          <CardBody>
            <h5>{last_year_average_rate || 0}¢</h5>
          </CardBody>
        </Card>
      </div>
    );
  };

  const changeUtilityId = (val: any) => {
    if (val) {
      setselectedTDU(val.utility_name);
      setUtilityId(val.utility_id);
    } else {
      setselectedTDU("All Utility");
      setUtilityId("");
    }
  };

  return (
    <div className="layout-width">
      <Card>
        <CardHeader className="align-items-center justify-content-between d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Average Rate</h4>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-wrap gap-2 mb-3">
            <TduSelector
              value={selectedTDU}
              handleChange={(val: string) => changeUtilityId(val)}
            />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div>{findData ? <AverageRate /> : <NoResult />}</div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default USN_AverageRateGlance;
