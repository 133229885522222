import React, { useEffect, useState } from "react";
import Loader from "./Common/Loader";
import "../styles/myplan.css";
import { Card, CardBody, CardHeader } from "reactstrap";
import { NoResult } from "./Common/NoResult";
import { useSelector } from "react-redux";
import { fetchUSNAllPTCRate } from "../api/stateInsight";
import { MyBarChart } from "./MyBarChart";
import { BarDatum } from "@nivo/bar";

const USN_AllPTCRate = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<BarDatum[]>([]);
  const [termValue, setTermValue] = useState("All");
  const [findData, setFindData] = useState(true);

  const getPlanList = async () => {
    setIsLoading(true);
    setData([]);
    setFindData(false);
    const res = await fetchUSNAllPTCRate(currentLocation?.state_id, "1");
    if (res.status !== 1 || res.response.length === 0) {
      setFindData(false);
      return setIsLoading(false);
    }
    setFindData(true);
    const filteredData = res.response.filter((item: any) => item.ptc !== 0);

    setData(filteredData);
    setIsLoading(false);
  };

  useEffect(() => {
    getPlanList();
  }, [currentLocation]);

  const filterSelections = () => {
    let list = [],
      key = 0;
    if (termValue !== "All") {
      list.push(
        <button
          key={key++}
          className="d-flex align-items-center btn-soft-secondary btn-sm me-2"
          onClick={() => setTermValue("All")}
        >
          {`Term: ${termValue}`}
          <i className="bx bx-x fs-15 ms-2" />
        </button>
      );
    }
    return list.length > 0 ? (
      <div className="d-flex align-items-center">
        Active Filters:
        <div className="d-flex ms-3">{list}</div>
      </div>
    ) : null;
  };

  return (
    <div className="layout-width">
      <Card style={{ minHeight: "30rem" }}>
        <CardHeader className="align-items-center justify-content-between d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            All PTC Rate in {currentLocation?.state_name}
          </h4>
        </CardHeader>
        <CardBody>
          <div className="mb-4 d-flex justify-content-between">
            <div>{filterSelections()}</div>
          </div>

          {isLoading ? (
            <Loader />
          ) : (
            <div className="rdt_out">
              {findData ? (
                <MyBarChart
                  type="AllPTCRateBarChart"
                  // title={`${range}-Month Rate Trends`}
                  // footer="Month/Year"
                  data={data}
                />
              ) : (
                <NoResult />
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
export default USN_AllPTCRate;
