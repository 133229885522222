import { api } from "../config";
import { getToken } from "./common";

export const fetchUSNRaceData = async (state_id, service_type, utility_id) => {
  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("service_type", service_type || "1");
  if (utility_id) formdata.append("utility_id", utility_id);
  const requestOptions = {
    method: "POST",
    // headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Home/mobility_rank`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNProviders = async () => {
  const requestOptions = {
    method: "GET",
    // headers: myHeaders,
    // body: formdata,
    redirect: "follow",
    // responseType: 'blob',
  };
  const url = `${api.API_URL}USN/Home/get_providers`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNUtilityId = async (state_id) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Home/get_state_utility?state_id=${state_id}`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNAverageRate = async (
  state_id,
  utility_id,
  service_type
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("service_type", service_type || "1");
  if (utility_id) formdata.append("utility_id", utility_id);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Home/average_rate`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNAvgPriceTrend = async (
  state_id,
  utility_id,
  type,
  provider_id,
  service_type
) => {
  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("type", type);
  formdata.append("service_type", service_type || "1");
  if (utility_id) formdata.append("utility_id", utility_id);
  if (provider_id) formdata.append("provider_id", provider_id);

  const requestOptions = {
    method: "POST",
    // headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Trend/market_average_price`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNLowestPriceTrend = async (
  state_id,
  utility_id,
  type,
  provider_id,
  service_type
) => {
  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("type", type);
  formdata.append("service_type", service_type || "1");
  if (utility_id) formdata.append("utility_id", utility_id);
  if (provider_id) formdata.append("provider_id", provider_id);

  const requestOptions = {
    method: "POST",
    // headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Trend/market_lowest_price`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNPTCRateTrend = async (
  state_id,
  utility_id,
  type,
  service_type
) => {
  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("utility_id", utility_id);
  formdata.append("type", type);
  formdata.append("service_type", service_type || "1");

  const requestOptions = {
    method: "POST",
    // headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Trend/ptc_hisrory_rate`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const getUSNPlanNum = async (state_id, service_type) => {
  const requestOptions = {
    method: "GET",
    // headers: myHeaders,
    // body: formdata,
    redirect: "follow",
    // responseType: 'blob',
  };
  const url = `${
    api.API_URL
  }USN/Home/get_plan_provider_number?state_id=${state_id}&service_type=${
    service_type || "1"
  }`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchPeakTimeRateTrends = async (
  state_id,
  utility_id,
  type,
  term,
  provider_id,
  service_type
) => {
  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("utility_id", utility_id);
  formdata.append("type", type);
  formdata.append("service_type", service_type || "1");
  if (term) formdata.append("term", term);
  if (provider_id) formdata.append("provider_id", provider_id);

  const requestOptions = {
    method: "POST",
    // headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Trend/rate_trends_by_peak_time`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNProviderList = async (state_id, service_type) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  const url = `${
    api.API_URL
  }USN/Market/get_login_provider?state_id=${state_id}&service_type=${
    service_type || "1"
  }`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNMarketPlan = async (
  state_id,
  created_ts,
  utility_id,
  service_type
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("created_ts", created_ts);
  formdata.append("service_type", service_type || "1");
  if (utility_id) formdata.append("utility_id", utility_id);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Market/usn_plans`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNMarketPlanDetail = async (plan_id, pd_id, date) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("plan_id", plan_id);
  formdata.append("pd_id", pd_id);
  formdata.append("date", date);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Market/usn_plan_detail`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const exportUSNPlan = async (state_id, created_ts, service_type) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("created_ts", created_ts);
  formdata.append("service_type", service_type || "1");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
    // responseType: 'blob',
  };
  const url = `${api.API_URL}USN/Market/export_usn_plans`;
  const response = await fetch(url, requestOptions);
  return response;
};

export const fetchUSNMarketAvgPrice = async (
  state_id,
  utility_id,
  date,
  service_type,
  provider_id
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("date", date);
  formdata.append("service_type", service_type || "1");
  if (utility_id) formdata.append("utility_id", utility_id);
  if (provider_id) formdata.append("provider_id", provider_id);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Market/market_average_price`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNMarketLowestPrice = async (
  state_id,
  utility_id,
  date,
  service_type,
  provider_id
) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("date", date);
  formdata.append("service_type", service_type || "1");
  if (utility_id) formdata.append("utility_id", utility_id);
  if (provider_id) formdata.append("provider_id", provider_id);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Market/market_lowest_price`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNMarketAverageRate = async (state_id, service_type) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("service_type", service_type || "1");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Market/market_price`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchMarketPTCRateUSN = async (state_id, service_type) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("service_type", service_type || "1");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Market/market_ptc_price`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNStateProviders = async (state_id, service_type) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    // body: formdata,
    redirect: "follow",
    // responseType: 'blob',
  };
  const url = `${
    api.API_URL
  }USN/Market/get_state_provider?state_id=${state_id}&service_type=${
    service_type || "1"
  }`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchUSNPTCRetailHeadroom = async (
  state_id,
  utility_id,
  type,
  service_type
) => {
  const formdata = new FormData();
  formdata.append("state_id", state_id);
  formdata.append("type", type);
  formdata.append("service_type", service_type || "1");
  if (utility_id) formdata.append("utility_id", utility_id);

  const requestOptions = {
    method: "POST",
    // headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Trend/retail_headroom_by_ptc`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchStateList = async () => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Market/get_state_list`;

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      const errorText = await response.json();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};

export const fetchPTCUtility = async (state_id) => {
  const requestOptions = {
    method: "GET",
    // headers: myHeaders,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Trend/get_ptc_utility_list?state_id=${state_id}`;
  const response = await fetch(url, requestOptions);
  return response.json();
};

export const fetchPeakTimeUtility = async (state_id) => {
  const requestOptions = {
    method: "GET",
    // headers: myHeaders,
    redirect: "follow",
  };
  const url = `${api.API_URL}USN/Trend/get_peak_utility_list?state_id=${state_id}`;
  const response = await fetch(url, requestOptions);
  return response.json();
};
