import React, { useContext, useState } from "react";
import "../styles/myplan.css";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Utility } from "../App";
import { UtilityDataTypes } from "../types";
import { useSelector } from "react-redux";

interface TduSelectorProps {
  value: string;
  handleChange: Function;
  variant?: string;
  options?: any;
}

const TduSelector: React.FC<TduSelectorProps> = ({
  value,
  handleChange,
  variant,
  options,
}) => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);

  const isTX = currentLocation?.state_id === "TX";

  const { utilityList } = useContext(Utility);
  const [isOpen, setIsOpen] = useState(false);
  
  if (utilityList?.length === 0) return null;

  if (variant === "buttonGroup") {
    return (
      <div className="d-flex flex-wrap gap-2">
        {(options || utilityList).map((item: UtilityDataTypes) => (
          <Button
            key={item.utility_id}
            onClick={() => handleChange(item.utility_id)}
            color={value === item.utility_id ? "secondary" : "light"}
          >
            {item.utility_name}
          </Button>
        ))}
      </div>
    );
  }

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen((prevState) => !prevState)}
    >
      <DropdownToggle tag="button" className="btn btn-light">
        {value ? value : (isTX ? "All TDU" : "All Utility")} <i className="mdi mdi-chevron-down"></i>
      </DropdownToggle>
      <DropdownMenu style={{ overflow: "scroll", height: "auto" }}>
        <DropdownItem key="all" onClick={() => handleChange("")}>
          {isTX ? "All TDU" : "All Utility"}
        </DropdownItem>
        {(options || utilityList).map((item: UtilityDataTypes) => (
          <DropdownItem
            key={item.utility_id}
            onClick={() => handleChange(item)}
          >
            {item.utility_name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default TduSelector;
