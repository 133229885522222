import React, { useEffect, useState, useContext } from "react";
import Loader from "./Common/Loader";
import "../styles/market.css";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { MyChart } from "./MyChart";
// import { errornotify, successnotify } from "./Message";
// import { Utility } from "../App";
import { DataSeriesTypes } from "../types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPTCUtility,
  fetchUSNPTCRateTrend,
  fetchUSNPTCRetailHeadroom,
} from "../api/usnMarket";
import moment from "moment";
import TduSelector from "./TduSelector";
import { monthToType } from "../api/common";
import { Auth } from "aws-amplify";
import { changeGetAccessModalDisplay } from "../store/actions";
import Tooltip from "./Common/Tooltip";

const USN_PTCRateTrends = () => {
  // const { utilityList } = useContext(Utility);
  const dispatch = useDispatch();
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);

  const [utilityList, setUtilityList] = useState([]);
  const [utilityId, setUtilityId] = useState("");
  const [range, setRange] = useState("3");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<DataSeriesTypes[]>([]);
  const [dataSource, setDataSource] = useState([]);
  const [priceComposition, setPriceComposition] = useState<any>([]);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    fetchPTCUtility(currentLocation?.state_id).then((res) => {
      if (res?.status === 1) {
        setUtilityList(res?.response?.electricity || []);
        setUtilityId(res?.response?.electricity?.[0]?.utility_id || "");
      }
    });
  }, [currentLocation]);

  useEffect(() => {
    const checkAuth = async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) setIsLogin(true);
    };

    checkAuth();
  }, [Auth]);

  const getAvgPrice = async () => {
    setIsLoading(true);

    const requestData = {
      state_id: currentLocation?.state_id,
      utilityId: utilityId,
      type: monthToType(range),
    };

    const res = await fetchUSNPTCRateTrend(...Object.values(requestData));
    let resData = [];
    if (res.status === 1) {
      resData = res.response;
      const { data_source } = res || {};
      if (data_source) {
        setDataSource(Object.values(data_source));
      }
    } else {
      setIsLoading(false);
      // return errornotify(res.message);
    }

    const ptcData = resData.map((item: any) => {
      if (item.ptc !== 0) {
        return {
          x: moment(item.date, "YYYY-MM").format("MM/DD/YYYY"),
          y: +item.ptc,
        };
      } else {
        return {
          x: moment(item.date, "YYYY-MM").format("MM/DD/YYYY"),
          y: null,
        };
      }
    });

    setData([
      {
        id: "PTC Rate",
        data: ptcData.filter((i: any) => i.y),
      },
    ]);
    setIsLoading(false);
  };

  const getDetail = async () => {
    const requestData = {
      state_id: currentLocation?.state_id,
      utilityId: utilityId,
      type: monthToType(range),
    };

    const res = await fetchUSNPTCRetailHeadroom(...Object.values(requestData));

    if (res?.status === 1) {
      const { response } = res || {};

      setPriceComposition(response);
    } else {
      setPriceComposition([]);
      // return errornotify(res.message);
    }
  };

  useEffect(() => {
    if (!utilityId) return;
    getAvgPrice();
    getDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utilityId, range]);

  const checkAuthAndSetValue = async (val: string) => {
    if (val !== "3") {
      const auth = await Auth.currentUserInfo();
      if (auth) {
        setRange(val);
      } else {
        // if (localStorage.getItem("received_inquiry")) {
        //   successnotify(
        //     "We have received your inquiry. Please be patient, and we will contact you as soon as possible!"
        //   );
        // } else {
        dispatch(changeGetAccessModalDisplay(true));
        // }
      }
    } else {
      setRange(val);
    }
  };

  if (utilityList.length === 0) return null;
  return (
    <div className="layout-width">
      <Card style={{ minHeight: "600px" }}>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Rate Trends by PTC</h4>
          {dataSource && isLogin && (
            <Tooltip
              text={
                <div>
                  {dataSource.map((item, idx) => (
                    <p key={idx}>
                      <a href={item}>{item}</a>
                    </p>
                  ))}
                </div>
              }
            />
          )}
        </CardHeader>
        <CardBody>
          <div className="d-flex justify-content-between flex-wrap gap-2 mb-5">
            <TduSelector
              variant="buttonGroup"
              value={utilityId}
              handleChange={(val: string) => setUtilityId(val)}
              options={utilityList}
            />
            <div className="d-flex align-items-center flex-wrap gap-2">
              <span>Range:</span>
              {["3", "6", "12"].map((val: string, idx: number) => (
                <Button
                  onClick={() => checkAuthAndSetValue(val)}
                  color={range === val ? "secondary" : "light"}
                  key={idx}
                >
                  {val} Months
                </Button>
              ))}
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <MyChart
                type="ptcRateTrend"
                title={`${range}-Month Rate Change`}
                // footer="Month/Year"
                data={data[0]?.data.length ? data : []}
                tooltipDetail={priceComposition}
              />
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
export default USN_PTCRateTrends;
