import React, { useEffect, useState } from "react";
import Loader from "./Common/Loader";
import "../styles/market.css";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { MyChart } from "./MyChart";
import { DataSeriesTypes } from "../types";
// import { errornotify, successnotify } from "./Message";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUSNAvgPriceTrend,
  fetchUSNLowestPriceTrend,
} from "../api/usnMarket";
import TduSelector from "./TduSelector";
import { monthToType } from "../api/common";
import { Auth } from "aws-amplify";
import { changeGetAccessModalDisplay } from "../store/actions";
import Tooltip from "./Common/Tooltip";

const USN_ElectricityRateTrends = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const dispatch = useDispatch();
  const [utilityId, setUtilityId] = useState("");
  const [range, setRange] = useState("3");
  const [selectedTDU, setselectedTDU] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const [data, setData] = useState<DataSeriesTypes[]>([]);

  useEffect(() => {
    const checkAuth = async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) setIsLogin(true);
    };

    checkAuth();
  }, [Auth]);

  useEffect(() => {
    setselectedTDU("All Utility");
    setUtilityId("");
  }, [currentLocation]);

  const getAvgPrice = async () => {
    setIsLoading(true);

    const requestData = {
      state_id: currentLocation?.state_id,
      utilityId: utilityId,
      type: monthToType(range),
    };

    const res = await fetchUSNAvgPriceTrend(...Object.values(requestData));
    const low_res = await fetchUSNLowestPriceTrend(
      ...Object.values(requestData)
    );

    let resData = [];
    if (res.status === 1) {
      resData = res.response.average_price;
    } else {
      setIsLoading(false);
      // return errornotify(res.message);
    }

    const avgData = resData.map((item: any) => {
      if (item.average_price !== 0) {
        return { x: item.date, y: +item.average_price };
      } else {
        return { x: item.date, y: null };
      }
    });

    let low_resData: any[] = [];
    if (low_res.status === 1) {
      low_resData = low_res.response.lowest_price;
    } else {
      setIsLoading(false);
      // return errornotify(low_res.message);
    }

    let lowData: any = [];
    if (low_resData.length !== 0)
      low_resData.map((item: any) => {
        if (item.lowest_price !== 0) {
          return lowData.push({ x: item.date, y: +item.lowest_price });
        } else {
          return lowData.push({ x: item.date, y: null });
        }
      });

    setData([
      {
        id: "Lowest Price",
        data: lowData.filter((i: any) => i.y),
      },
      {
        id: "Average Price",
        data: avgData.filter((i: any) => i.y),
      },
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    getAvgPrice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, range, utilityId]);

  const changeUtilityId = (val: any) => {
    if (val) {
      setselectedTDU(val.utility_name);
      setUtilityId(val.utility_id);
    } else {
      setselectedTDU("All Utility");
      setUtilityId("");
    }
  };

  const checkAuthAndSetValue = async (val: string) => {
    if (val !== "3") {
      const auth = await Auth.currentUserInfo();
      if (auth) {
        setRange(val);
      } else {
        // if (localStorage.getItem("received_inquiry")) {
        //   successnotify(
        //     "We have received your inquiry. Please be patient, and we will contact you as soon as possible!"
        //   );
        // } else {
        dispatch(changeGetAccessModalDisplay(true));
        // }
      }
    } else {
      setRange(val);
    }
  };

  return (
    <div className="layout-width">
      <Card style={{ minHeight: "600px" }}>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Rate Trends by Electricity
          </h4>
          {isLogin && (
            <Tooltip
              text={
                <div>
                  Average Price = Total price of the current utility / number of
                  selected months
                  <br />
                  Lowest Price = The lowest monthly price of the current utility
                  within the selected time period
                </div>
              }
            />
          )}
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-wrap justify-content-between gap-2">
            <TduSelector
              value={selectedTDU}
              handleChange={(val: string) => changeUtilityId(val)}
            />
            <div className="d-flex align-items-center flex-wrap gap-2">
              <span>Range:</span>
              {["3", "6", "12"].map((val: string, idx: number) => (
                <Button
                  onClick={() => checkAuthAndSetValue(val)}
                  color={range === val ? "secondary" : "light"}
                  key={idx}
                >
                  {val} Months
                </Button>
              ))}
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <MyChart
                type="homeprice"
                title={`${range}-Month Rate Trends`}
                footer="Month/Year"
                data={data[0]?.data.length ? data : []}
              />
            </React.Fragment>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
export default USN_ElectricityRateTrends;
