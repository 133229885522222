import Home from "./home";
import MarketTrend from "../../Components/MarketTrend";
import LoginMarketAverageRate from "../../Components/LoginMarketAverageRate";
import PowerRateIndex from "../../Components/PowerRateIndex";
import { useSelector } from "react-redux";
import GetAccessModal from "../../Components/Common/GetAccessModal";
import USN_AverageRateGlance from "../../Components/USN_AverageRateGlance";
import USN_PeaktimeRateTrends from "../../Components/USN_PeaktimeRateTrends";
import USN_ElectricityRateTrends from "../../Components/USN_ElectricityRateTrends";
import USN_PTCRateTrends from "../../Components/USN_PTCRateTrends";
import USN_PTCRetailHeadroom from "../../Components/USN_PTCRetailHeadroom";

const Index = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);

  return (
    <div className="layout-wrapper landing">
      <Home />
      <div className="bg-light pt-5 pb-5">
        {currentLocation?.state_id === "TX" ? <TxComps /> : <UsnComps />}
      </div>
    </div>
  );
};

const TxComps = () => {
  return (
    <>
      <MarketTrend />
      <PowerRateIndex />
      <LoginMarketAverageRate />
    </>
  );
};

const UsnComps = () => {
  return (
    <>
      <USN_ElectricityRateTrends />
      <USN_PTCRateTrends />
      {/* <USN_PTCRetailHeadroom /> */}
      <USN_PeaktimeRateTrends />
      <USN_AverageRateGlance />
      <GetAccessModal />
    </>
  );
};

export default Index;
