import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import imgpattern from "../../assets/images/landing/img-pattern.png";
import { RaceChart } from "../../Components/RaceChart";
import { getPlanNum } from "../../api/market";
import { useSelector } from "react-redux";
import { getUSNPlanNum } from "../../api/usnMarket";

const Home = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const isTX = currentLocation?.state_id === "TX";

  const [nums, setNums] = useState({
    plan_nums: 0,
    provider_nums: 0,
  });

  useEffect(() => {
    const getNum = async () => {
      const res = isTX ? await getPlanNum() : await getUSNPlanNum(currentLocation?.state_id);
      if (res.status === 1) setNums(res.response);
    };

    getNum();
  }, [currentLocation]);

  return (
    <React.Fragment>
      <section className="section pb-4 hero-section" id="hero">
        <div className="bg-overlay bg-overlay-pattern"></div>
        <Container className="mt-5 pt-5">
          <Row>
            <Col xs={12} md={6}>
              <div className="d-flex flex-column">
                <h1 className="display-6 fw-bold mt-4 mb-4">
                  Powerful Tool for Retail Energy Pricing and Competitive
                  Analysis
                </h1>
                <div className="d-flex gap-5">
                  <div>
                    <CountUp
                      start={0}
                      separator=","
                      end={nums.provider_nums}
                      duration={1}
                      className="display-6 fw-semibold text-secondary"
                    />
                    <p className="text-muted">Number of REPs</p>
                  </div>
                  <div>
                    <CountUp
                      start={0}
                      separator=","
                      end={nums.plan_nums}
                      duration={1}
                      className="display-6 fw-semibold text-secondary"
                    />
                    <p className="text-muted">Number of Plans</p>
                  </div>
                </div>
                <div className="d-flex gap-3 mt-5 mb-5">
                  <Link to="/contact-us" className="btn btn-outline-secondary">
                    Talk To Sales{" "}
                    <i className="ri-headphone-line align-middle ms-1"></i>
                  </Link>
                  <Link to="/contact-us" className="btn btn-secondary">
                    Get Started{" "}
                    <i className="ri-arrow-right-line align-middle ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="demo-carousel">
                <div className="demo-img-patten-top d-none d-sm-block">
                  <img src={imgpattern} className="d-block img-fluid" alt="" />
                </div>
                <div className="demo-img-patten-bottom d-none d-sm-block">
                  <img src={imgpattern} className="d-block img-fluid" alt="" />
                </div>
                <Card>
                  <CardBody>
                    <RaceChart />
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Home;
